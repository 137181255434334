angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('signAdvanced', {
            url: '/signDocument/advanced/:token',
            views: {
                "content": {
                    controller: 'SignAdvancedCtrl',
                    templateUrl: 'web/sign/advanced/advanced.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Sign Document'
            }
        });
    })
    .controller('SignAdvancedCtrl', function ($rootScope, $scope, swalService, $mdSidenav, signatureService, $stateParams, $sce, $timeout, $translate, idCardModal, $location, $anchorScroll, $window, $state) {
        var that = this;
        that.root = $rootScope;
        that.toggleOptions = () => $mdSidenav('options').toggle();

        ////////////////////////
        // Inicio INICIALIZAR
        ///////////////////////
        that.token = $stateParams.token;
        that.selectedwidgeetIndex = 0;
        that.pageURLS = [];

        signatureService.retrieveFirmaByToken(that.token).then(function (response) {
            that.loaded = true;
            that.sign = response.data.signature;
            that.firmaMultiple = response.data.signatureSinger;
            for (var k = 0; k < that.sign.signers.length; k++) {
                if (that.sign.signers[k].id == that.firmaMultiple.id) {
                    that.actualFirmante = that.sign.signers[k];
                    that.actualFirmanteIndex = k;
                    that.selectedwidgeet = that.actualFirmante.fields[0];
                }
            }
        }, function (response) {
            swal($translate.instant('Algo ha ido mal'), $translate.instant("Esta firma ya no está disponible"), "error");
            $timeout(function () {
                window.location = "https://" + $rootScope.dominio;
            }, 2000);
        });

        ////////////////////////
        // fin INICIALIZAR
        ///////////////////////




        that.siguiente = function () {
            if (that.actualFirmante.fields) {
                if (that.selectedwidgeetIndex < that.actualFirmante.fields.length - 1) {
                    that.selectedwidgeetIndex++;
                    that.selectedwidgeet = that.actualFirmante.fields[that.selectedwidgeetIndex];
                    that.goTo('campo' + that.actualFirmanteIndex + '_' + that.selectedwidgeetIndex, that.selectedwidgeet);
                    return;
                } else {
                    for (var i = 0; i < that.actualFirmante.fields.length; i++) {
                        if (that.actualFirmante.fields[i].type != 'circle' && that.actualFirmante.fields[i].type != 'check' && that.actualFirmante.fields[i].type != 'cross' && !that.actualFirmante.fields[i].answer) {
                            that.selectedwidgeet = that.actualFirmante.fields[i];
                            that.selectedwidgeetIndex = i;
                            that.goTo('campo' + that.actualFirmanteIndex + '_' + that.selectedwidgeetIndex, that.selectedwidgeet);
                            // swal($translate.instant("Hay campos sin rellenar"), $translate.instant("Por favor, rellena todos los campos para poder completar la firma."));
                            return;
                        }
                    }
                }
            }
            that.firmar();
        };
        that.anterior = function () {
            if (that.selectedwidgeetIndex > 0) {
                that.selectedwidgeetIndex--;
                that.selectedwidgeet = that.actualFirmante.fields[that.selectedwidgeetIndex];
                that.goTo('campo' + that.actualFirmanteIndex + '_' + that.selectedwidgeetIndex, that.selectedwidgeet);
                return;
            }
        };



        that.rechazar = () =>
            swalService.requestSwal("Rechazar", 'Una vez rechazado, no se podrá volver a firmar ni editar.', 'info', () => signatureService.rechazarByToken(that.token, that.actualFirmante))
                .then(response => {
                    swal($translate.instant("Documento rechazado"), $translate.instant("Se ha rechazado el documento correctamente"), "success");
                    window.location = "https://" + $rootScope.dominio;
                }, error => swal($translate.instant('Algo ha ido mal'), $translate.instant("Ha habido un error rechazando el documento"), "error")
                );

        that.checkCompleted = function () {
            for (let k = 0; that.actualFirmante && k < that.actualFirmante.fields.length; k++) {
                if ((that.actualFirmante.fields[k].type != 'circle' && that.actualFirmante.fields[k].type != 'check' && that.actualFirmante.fields[k].type != 'cross') && !that.actualFirmante.fields[k].answer) {
                    return false;
                }
            }
            return true;
        };

        that.firmar = function () {
            if (!that.checkCompleted()) {
                swal($translate.instant("Faltan campos por rellenar"), $translate.instant("Rellena todos los campos para poder terminar el proceso de firma"), "warning");
                return;
            }

            if (that.actualFirmante.requireId) {
                idCardModal.openModal(that.token).result.then(function (card) {
                    signToServer();
                });
                return;
            }

            signToServer();
        };


        function signToServer() {
            swalService.requestSwal("Firmar documento", 'Una vez firmado, no se podrá volver a firmar ni editar.', 'info', () => signatureService.firmarByToken($stateParams.token, that.actualFirmante))
                .then(function (response) {
                    swal($translate.instant("Documento Firmado"), $translate.instant("Has firmado el documento correctamente"), "success");
                    if (FingerinkEnvironmentAngular.trackGoogleFacebookTags) {
                        $window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'Signatures',
                            eventAction: 'sign',
                            eventLabel: 'Advanced'
                        });
                    }
                    // window.location = "https://" + $rootScope.dominio;
                    // he tirado por la forma facil ,ahora tu pasale variables y dejalo funcional de verdad xd
                    $state.go('endSign');
                }, error => swal($translate.instant("Error firmando el documento"), $translate.instant("No se ha podido firmar el documento"), "error")
                );
        }


        that.call = function (s, id) {
            $rootScope.$broadcast(s + id, 'call');
        };

        that.goTo = function (a, w) {
            $location.hash(a);
            $anchorScroll();
            if (w) {
                that.selectedwidgeet = w;
            }
        };
        $timeout(function () {
            that.goTo('campo' + that.actualFirmanteIndex + '_' + 0, that.selectedwidgeet);
        }, 10);

    });
